import { flatten } from "lottie-colorify";
import React, { useEffect, useState } from "react";

import { useLottieWeb } from "gyg_common/hooks";

import config from "../../../config";
import resetButtonAnimation from "../../assets/gif/resetButton.json";
import colours from "../../styles/colours";
import { webLoaderStyle } from "./Spinner.styles";

// web spinner loader
interface SpinnerProps {
  containerStyle?: React.CSSProperties;
  color?: string;
  autoPlay?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  containerStyle = {},
  color,
  autoPlay = true,
}) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const { lottie } = useLottieWeb();

  useEffect(() => {
    if (!lottie || !wrapperRef) {
      return;
    }

    lottie.destroy();
    lottie.loadAnimation({
      container: wrapperRef,
      renderer: "canvas",
      loop: true,
      autoplay: autoPlay ? autoPlay : !config.visualTestingMode,
      animationData: flatten(color ?? colours.yellow, resetButtonAnimation),
    });
    if (autoPlay == false) {
      lottie?.pause();
    }
  }, [color, autoPlay, lottie, wrapperRef]);

  return (
    <div
      style={{ ...webLoaderStyle, ...containerStyle }}
      ref={setWrapperRef}
      data-testid='spinner-loader'
      id='spinner-loader'
    />
  );
};

export default Spinner;
